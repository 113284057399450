import * as types from '../types';

/* eslint-disable default-param-last */
const INITIAL_STATE = {
  isLoggedin: false,
  token: null,
  user: {},
  isLoading: false,
};
// eslint-disable-next-line no-unused-vars
export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS: {
      const newState = { ...state };
      newState.isLoggedin = true;
      newState.isLoading = false;
      newState.token = action.payload.token;
      newState.user = action.payload.user;

      return newState;
    }
    case types.SIGN_FAILURE: {
      const newState = { ...INITIAL_STATE };

      return newState;
    }
    case types.SIGN_IN_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    default: {
      return state;
    }
  }
}
