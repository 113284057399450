import styled from 'styled-components';
import * as colors from '../../Config/colors';

export const Wrapper = styled.aside`
  background-color: white;
  width: 350px;
  height: 708px;
  background: ${colors.dark.dark2};
  border-radius: 9px;
  overflow: auto;
  overflow-y: scroll;

  .video__information {
    margin: 32px 0 16px;
    height: 122px;
    h1 {
      font-size: 20px;
      line-height: 30px;
      margin: 32px 0 16px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: #4f6078;
    }
  }
  .player__action {
    h2 {
      font-size: 18px;
      margin: 16px 0;
    }
    button {
      background-color: #212c3b;
      padding: 32px;
    }
  }
  .video__item {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    border-radius: unset;
  }
  .video__center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
  }
  .video__thumb {
    width: 100px;
    height: 100%;
    background-color: #58606c;
    border-radius: 5px;
  }
  .video__description {
    width: 195px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 12px;
    h3 {
      font-size: 14px;
    }
    p {
      color: ${colors.light.light4};
      font-size: 12px;
    }
  }
  .video--active {
    background-color: #000;
  }
  .video--completed {
    border-left: 3px solid ${colors.primary.main};
  }
`;

export const ItemWrapper = styled.div`
  :first-child {
    .Item__videos {
      /* display: block; */
    }
    .Item__header {
      border-radius: 5px 5px 0 0;
    }
  }
  :last-child {
    .Item__header {
      border-radius: 0 0 5px 5px;
    }
  }
  .Item__videos {
    display: none;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    max-height: 508px;
  }
  .open {
    display: block;
  }
  .Item__header {
    height: 66px;
    background-color: #212c3b;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    border-radius: 0;
    align-items: center;
    width: 100%;
    cursor: pointer;
    h2 {
      font-size: 14px;
      font-weight: 600;
    }
    span {
      background-color: ${colors.primary.main};
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
