/* eslint-disable prettier/prettier */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Header } from '../../Components/Header';
import Playlist from '../../Components/Playlist';
import Item from '../../Components/Playlist/Item';
import { Container, Main, Player } from './style';

const playlist = [
  {
    id: 12321,
    module: 'React JS',
    videos: [
      {
        id: 1,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 2,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 3,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 4,
        title: 'Aula 01',
        duration: '23:11',
      },
    ],
  },
  {
    id: 1232,
    module: 'Node JS',
    videos: [
      {
        id: 1,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 2,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 3,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 4,
        title: 'Aula 01',
        duration: '23:11',
      },
    ],
  },
  {
    id: 123221,
    module: 'Angular',
    videos: [
      {
        id: 1,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 2,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 3,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 4,
        title: 'Aula 01',
        duration: '23:11',
      },
    ],
  },
  {
    id: 1232121,
    module: 'Angular',
    videos: [
      {
        id: 1,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 2,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 3,
        title: 'Aula 01',
        duration: '23:11',
      },
      {
        id: 4,
        title: 'Aula 01',
        duration: '23:11',
      },
    ],
  },

];

export default function Watch() {
  // playlist.videos.map((list) => console.log(list));
  return (
    <Container>
      <Header />
      <Main>
        <div className="main__center">
          <div className="video__player">
            <Player>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3r6MfUyNFwc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </Player>
            <div className="video__information">
              <h1>Como estudar o express do modo certo?</h1>
              <p>
                Disruptive acquisition strategy for board seat taking. First
                mover-advantage TechCrunch reader. Generalist-reading secondary
                markets buyer. TikToking, Secondary Market buyer. Miami-based VC
                scout.
              </p>
            </div>
            <div className="player__action">
              <h2>Material de Apoio</h2>
              <button type="button">Transferir Material</button>
            </div>
          </div>
          <Playlist>
            {playlist.map((item) => (
              <Item
                key={item.id}
                module={item.module}
                videos={item.videos}
              />
            ))}
          </Playlist>
        </div>
      </Main>
    </Container>
  );
}
