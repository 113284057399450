import styled from 'styled-components';
import * as colors from '../../../Config/colors';

export const Wrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    font-size: 14px;
  }
  span {
    font-size: 14px;
    color: rgb(211, 66, 66);
  }
  .input--error {
    border: 1px solid red !important;
  }
`;

export const Element = styled.input`
  height: 40px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  margin-top: 5px;
  padding: 16px 24px;
  height: 55px;
  border: 1px solid transparent;
  width: 100%;
  background-color: ${colors.dark.dark3};
  color: #4f6078;
  transition: 0.2s ease-out;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${colors.dark.dark4} inset !important;
    border: 1px solid ${colors.primary.light};
    -webkit-text-fill-color: ${colors.primary.light};
  }
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${colors.primary.subtle};
    color: ${colors.primary.subtle};
  }
  &:focus {
    border: 1px solid ${colors.primary.main};
    -webkit-text-fill-color: ${colors.primary.subtle};
    color: ${colors.primary.subtle};
  }
`;
