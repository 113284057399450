import styled from 'styled-components';
import * as colors from '../../Config/colors';

export const Wrapper = styled.header`
  background: ${colors.dark.dark2};
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .header__center {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 230px;
      .avatar {
        background-color: #fff;
        padding: 2px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .avatar__image {
        border-radius: 50%;
        border: 1.5px solid #000;
      }
      .user__logged {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90px;
      }
    }
  }
`;
