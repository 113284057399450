import styled from 'styled-components';
import * as colors from '../../Config/colors';

export const Login = styled.div`
  height: 400px;
`;
export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .main__center {
    display: flex;
    justify-content: space-between;
    max-width: 1108px;
    width: 100%;
    height: 100%;
    padding: 40px 0 80px;
    .video__player {
      max-width: 728px;
      width: 100%;
      height: 410px;
    }
    .video__playlist {
      background-color: white;
      width: 350px;
      height: 708px;
      background: ${colors.dark.dark2};
      border-radius: 9px;
    }
    .video__information {
      margin: 32px 0 16px;
      height: 122px;
      h1 {
        font-size: 20px;
        line-height: 30px;
        margin: 32px 0 16px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #4f6078;
      }
    }
    .player__action {
      h2 {
        font-size: 18px;
        margin: 16px 0;
      }
      button {
        background-color: #212c3b;
        padding: 32px;
      }
    }
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-auto-rows: max-content;
  overflow: auto;
`;

export const Player = styled.div`
  width: 100%;
  height: 410px;
  background: ${colors.dark.dark2};
  border-radius: 9px;
  iframe {
    width: 100%;
    height: 100%;
    border-radius: 9px;
  }
`;
