import styled from 'styled-components';
import * as colors from '../../Config/colors';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.dark.dark2};
  padding: 64px;
  max-width: 480px;
  height: 400px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  .form__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
  }
`;
