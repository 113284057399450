/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { ItemWrapper } from './style';

function Item({ module, videos }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <ItemWrapper className="item">
      <button
        type="button"
        onClick={() => setOpen(!isOpen)}
        className="Item__header"
      >
        <span>1</span>
        <h2>{module}</h2>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className={`Item__videos ${isOpen ? 'open' : ''}`}>
        {videos.map((video) => (
          <button type="button" className="video__item video--completed">
            <div className="video__center">
              <div className="video__thumb" />
              <div className="video__description">
                <h3>{video.title}</h3>
                <p>{video.duration} min</p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </ItemWrapper>
  );
}
export default Item;
