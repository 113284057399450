/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Container } from '../../styles/global';
import { Form } from './style';
import * as actions from '../../store/modules/auth/action';
// import Loading from '../../Components/Loading';
import Button from '../../Components/elements/Button';
import Input from '../../Components/elements/Input';
import { Sign } from '../../Components/sign/style';

const schema = yup
  .object({
    username: yup
      .string()
      .required('Insira o seu nome de usuário.')
      .min(4, 'Nome de usuário deve ter no mínimo 4 caracteres'),
    password: yup
      .string()
      .required('Insira a sua senha.')
      .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  })
  .required();

export default function Login() {
  const dispatch = useDispatch();
  // const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    const { username, password } = data;
    dispatch(actions.signInRequest(username, password));
  };
  // console.log(watch('username')); // watch input value by passing the name of it

  return (
    <Container>
      <Sign className="sign">
        <div className="sign__center">
          <section className="sign__headline">
            <div className="sign__brand">
              <h2>SKHOLE</h2>
            </div>
            <h1 className="sign__title">
              Faça o login para entrar na sua conta.
            </h1>
            <p className="sign__paragraph">Octoplus Developers - 2022</p>
          </section>

          <Form className="form_sign" onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="text"
              label="Nome de usuário"
              name="username"
              placeholder="Digite seu nome de usuário"
              register={register}
              required
              errors={errors}
            />
            <Input
              type="password"
              label="Senha"
              name="password"
              placeholder="Sua senha"
              register={register}
              required
              errors={errors}
            />
            <Button type="submit" value="Entrar" />
            <div className="form__links">
              <Link to="/recover-login">Esqueceu a sua senha?</Link>
              <Link to="/signup">Crie conta gratuitamente</Link>
            </div>
          </Form>
        </div>
        <footer>Octoplus Developers - 2022</footer>
      </Sign>
    </Container>
  );
}
