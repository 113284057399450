import React from 'react';
import { Container } from '../../styles/global';

export default function Page404() {
  return (
    <Container>
      <h1>Page404</h1>
    </Container>
  );
}
