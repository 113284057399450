import styled, { createGlobalStyle } from 'styled-components';
import * as colors from '../Config/colors';
import 'react-toastify/dist/ReactToastify.min.css';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }
  body{
    font-family: sans-serif;
    background: ${colors.dark.dark1};
    color: ${colors.light.light4};
    display: flex;
    justify-content: center;
    align-items: center;

  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  html, body, #root{
    min-height: 100%;
  }
  button{
    cursor: pointer;
    background: ${colors.dark.dark1};
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 700;
    transition: all 300ms;
  }
  button:hover{
    filter: brightness(75%)
  }
  a{
    text-decoration: none;
  color: ${colors.primary.main};
  }
  ul{
    list-style: none;
  }
  body .Toastify .Toastify__toast-container .Toastify__toast--error{
    background-color: red;
  }
  body .Toastify .Toastify__toast-container .Toastify__toast--success{
    background-color: blue;
  }
  .Toastify__toast-body > div:last-child {
    font-size: 1.4rem;
    color: #fff;
    font-weight:600;

  }
  .Toastify__toast-icon svg{
    fill: white;
  }
  .Toastify__progress-bar {
    background-color: #fff;
  }
  label, a, span, p{
    font-size: 1.4rem;
  }
  ::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.dark.dark4};
    border-radius: 3px;
    cursor: move;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
  }

`;

export const Container = styled.section`
  min-height: 100vh;
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
