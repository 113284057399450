import { applyMiddleware, createStore } from 'redux';
import persistStore from 'redux-persist/es/persistStore';
import createSagaMiddleware from 'redux-saga';
import persistedReducer from './modules/reduxPersist';
import rootReducers from './modules/rootReducers';
import rootSaga from './modules/rootSagas';

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  persistedReducer(rootReducers),
  // eslint-disable-next-line prettier/prettier
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
// export default Store;
