import * as types from '../types';

export function signInRequest(username, password) {
  return {
    type: types.SIGN_IN_REQUEST,
    payload: { username, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: { token, user },
  };
}

export function signUpRequest(payload) {
  return {
    type: types.SIGN_UP_REQUEST,
    payload,
  };
}

export function signFailure() {
  return { type: types.SIGN_FAILURE };
}
