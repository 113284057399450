import React from 'react';
// eslint-disable-next-line object-curly-newline
// import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Wrapper } from './style';
// import * as actions from '../../store/modules/auth/action';
// import history from '../../Services/history';

// import BellIcon from '../../Assets/icons/bell.svg';
// import ChevronIcon from '../../Assets/icons/chevron-down.svg';
// import CapIcon from '../../Assets/icons/graduation-cap.svg';
import avatar from '../../Assets/images/avatar.jpg';

export function Header() {
  // const dispatch = useDispatch();
  // const isLoggedin = useSelector((state) => state.auth.isLoggedin);
  // const handleLogout = (e) => {
  //   e.preventDefault();
  //   dispatch(actions.signFailure());
  //   history.push('/login');
  // };
  return (
    <Wrapper>
      <div className="header__center">
        <div className="header__logo">
          <h1>SKHOLE</h1>
        </div>
        <nav>
          <Link to="/">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6981 21.5547 10.4458 21.3031 10.27 21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          <Link to="/register">
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 10V16V10ZM2 10L12 5L22 10L12 15L2 10Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 12V17C9 20 15 20 18 17V12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          <Link to="/register">
            <div className="user__logged">
              <div className="avatar">
                <img
                  className="avatar__image"
                  src={avatar}
                  width={50}
                  height={50}
                  alt=""
                />
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
        </nav>
      </div>
    </Wrapper>
  );
}
