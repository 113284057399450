/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Wrapper } from './style';

function Playlist({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

export default Playlist;
