/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch } from 'react-router-dom';

import Login from '../Pages/Login';
import Register from '../Pages/Register';
// import Aluno from '../Pages/Aluno';
import Alunos from '../Pages/Alunos';
import Watch from '../Pages/Watch';
import Page404 from '../Pages/Page404';
import Route from './MyRoute';

export default function MyRoutes() {
  return (
    <Switch>

      <Route exact path="/signup" component={Register} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/recover-login" component={Login} />

      <Route exact path="/" component={Alunos} isClosed />
      <Route exact path="/watch" component={Watch} />
      {/* <Route exact path="/courses" component={Fotos} isClosed />
      <Route exact path="/courses/:id" component={Fotos} isClosed /> */}

      <Route path="*" component={Page404} />
    </Switch>
  );
}
