/* eslint-disable require-yield */
/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line object-curly-newline
import { call, all, put, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import * as actions from './action';
import * as types from '../types';
import api from '../../../Services/axios';
import history from '../../../Services/history';

export function* signIn({ payload }) {
  try {
    const { username, password } = payload;

    const response = yield call(api.post, '/sessions', { username, password });
    const { token, user } = response.data;

    yield put(actions.signInSuccess(token, user));

    api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

    history.push('/');
  } catch (e) {
    toast.error('Usuario ou senha invalida');
    yield put(actions.signFailure());
  }
}

function persistRehydrate({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

// eslint-disable-next-line consistent-return
function* signUp({ payload }) {
  // const {
  // first_name,
  // last_name,
  // username,
  // phone,
  // email,
  // password,
  // } = payload;

  try {
    yield call(api.post, '/students', payload);

    yield put(actions.signUpRequest(payload));
    history.push('/');
  } catch (error) {
    toast.error('Erro desconhecido, estamos resolvendo.');
    yield put(actions.signFailure());
  }
}
export default all([
  takeLatest(types.SIGN_IN_REQUEST, signIn),
  takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
  takeLatest(types.SIGN_UP_REQUEST, signUp),

]);
