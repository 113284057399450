/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function MyRoute({ component: Component, isClosed, ...rest }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedin);
  if (isClosed && !isLoggedIn) {
    return (
      <Redirect to={{ pathname: '/Login', state: rest.location.pathname }} />
    );
  }

  return <Route {...rest} component={Component} />;
}

MyRoute.defaultProps = { isClosed: false };

MyRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isClosed: PropTypes.bool,
};
