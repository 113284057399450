import styled from 'styled-components';
import * as colors from '../../../Config/colors';

export const Wrapper = styled.button`
  background-color: ${colors.primary.main};
  width: 100%;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: bold;
`;
