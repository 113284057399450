import styled from 'styled-components';
import * as colors from '../../Config/colors';

export const Sign = styled.div`
  padding: 32px;
  footer {
    font-size: 1.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  .sign__center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sign__headline {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    flex-direction: column;
    max-height: 400px;
    max-width: 480px;
    width: 100%;
    height: 100%;
  }
  .sign__brand {
    h2 {
      color: ${colors.primary.main};
      font-size: 2.4rem;
    }
  }
  .sign__title {
    font-size: 40px;
    font-weight: 600;
  }
  .sign__paragraph {
    font-size: 14px;
  }
  .form__links {
    font-size: 14px;
  }
  .sign__title {
    font-size: 36px;
    line-height: 46px;
    margin-top: 40px;
    margin-bottom: 16px;
    text-align: left;
  }
  .sign__paragraph {
    margin-bottom: 16px;
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 30px;
    }
    p,
    label,
    a {
      font-size: 14px;
    }

    .form_sign {
      padding: 32px;
    }
    .sign__center {
    }
    .sign__links {
      flex-wrap: wrap;
      gap: 8px;
    }
    .sign__title {
      font-size: 30px;
      line-height: 44px;
      margin-top: 40px;
      margin-bottom: 16px;
      text-align: left;
    }
    .sign__paragraph {
      margin-bottom: 16px;
    }
    .sign__brand {
      text-align: center;
    }
    .form__links {
      flex-direction: column;
      gap: 8px;
    }
  }
  @media (max-width: 768px) {
    .sign__brand,
    .sign__paragraph {
      text-align: center;
    }
    .sign__paragraph {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    footer {
      display: none;
    }
  }
  @media (max-width: 900px) {
    .sign__center {
      flex-direction: column;
    }
  }
`;
