/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Element } from './styles';

function Input(props) {
  const { type, register, required, label, name, errors } = props;

  return (
    <Wrapper>
      <label htmlFor={name}>
        {label}
        <Element type={type} name={name} {...register(name, { required })} />
      </label>

      <span>{errors[name]?.message}</span>
    </Wrapper>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  errors: PropTypes.object,
};
Input.defaultProps = {
  value: '',
  name: '',
  errors: null,
};

export default Input;
