import * as types from '../types';

/* eslint-disable default-param-last */
const INITIAL_STATE = { profile: null };
// eslint-disable-next-line no-unused-vars
export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SIGN_IN_SUCCESS: {
      const newState = { ...state };
      newState.profile = action.payload.user;

      return newState;
    }

    default: {
      return state;
    }
  }
}
