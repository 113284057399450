import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

function Button({ type, value }) {
  return <Wrapper type={type}>{value}</Wrapper>;
}
Button.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
Button.defaultProps = {};
export default Button;
