export const primary = {
  main: '#1E71E8',
  dark: '#135CC3',
  light: '#82B5FF',
  subtle: '#DBEAFF',
};

export const dark = {
  dark1: '#05070A',
  dark2: '#0C1217',
  dark3: '#192630',
  dark4: '#2E3E4A',
};

export const light = { light4: '#DDE5E9' };
